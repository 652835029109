<template>
  <Layout>
    <div class="app-ms-list">
      <div class="tool">
        <a-form layout="inline">
          <a-button @click="add" type="primary">
            <a-icon type="file-add" /> 新建秒杀活动
          </a-button>
        </a-form>
      </div>
      <div class="table-wrap">
        <a-table :columns="columns" :data-source="list" :pagination="false">
          <span slot="img" slot-scope="img">
            <img :src="img" class="product-img" />
          </span>
          <span slot="startTime" slot-scope="time">{{
            time | formatDate
          }}</span>
          <span slot="endTime" slot-scope="time">{{ time | formatDate }}</span>
          <span slot="action" slot-scope="_, record">
            <a v-if="record.status === 0" @click="updateStatus(record.id, 1)">
              结束活动
            </a>
            <!-- <a v-if="record.status === 1" @click="updateStatus(record.id, 9)">
              删除活动
            </a> -->
          </span>
        </a-table>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/layout/index.vue";

const LimitedDiscountSvc = require("@/service/limited-discount");

const columns = [
  {
    title: "图片",
    align: "center",
    dataIndex: "img",
    key: "img",
    width: 60,
    scopedSlots: { customRender: "img" }
  },
  {
    title: "标题",
    dataIndex: "title",
    key: "title"
  },
  {
    title: "原价",
    align: "right",
    dataIndex: "price",
    key: "price",
    width: 100
  },
  {
    title: "秒杀价",
    dataIndex: "salePriceRange",
    key: "salePriceRange",
    width: 160,
    align: "right"
  },
  {
    title: "开始时间",
    align: "center",
    dataIndex: "startTime",
    key: "startTime",
    width: 160,
    scopedSlots: { customRender: "startTime" }
  },
  {
    title: "结束时间",
    align: "center",
    dataIndex: "endTime",
    key: "endTime",
    width: 160,
    scopedSlots: { customRender: "endTime" }
  },
  {
    title: "状态",
    align: "center",
    dataIndex: "statusStr",
    key: "statusStr",
    width: 100
  },
  {
    title: "操作",
    key: "action",
    width: 120,
    scopedSlots: { customRender: "action" }
  }
];

export default {
  name: "LimitedDiscount",

  data() {
    return {
      columns,

      list: []
    };
  },

  computed: {},

  methods: {
    add() {
      this.$router.push({ name: "LimitedDiscountEdit" });
    },

    async updateStatus(id, status) {
      const msg = status === 1 ? "确认结束此活动吗？" : "确认删除此活动吗？";
      this.$confirm({
        title: "操作提示",
        content: msg,

        onOk: () => {
          this._updateStatus(id, status);
        },
        onCancel() {}
      });
    },

    async _updateStatus(id, status) {
      try {
        await LimitedDiscountSvc.updateStatus(id, status);

        await this.fetch();
      } catch (err) {
        console.error(err);

        const msg = status === 1 ? "结束失败，错误：" : "删除失败，错误：";
        this.$message.error(msg + err.message);
      }
    },

    async fetch() {
      try {
        this.list = await LimitedDiscountSvc.list();
      } catch (err) {
        console.error(err);
        this.$message.error("加载数据出错，错误：" + err.message);
      }
    }
  },

  async mounted() {
    await this.fetch();
  },

  components: {
    Layout
  }
};
</script>

<style lang="scss">
.app-ms-list {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - var(--header-height));
  background: #f0f2f5;
  padding: 24px 24px 0;

  .tool {
    padding: 24px 24px;
    background: #fff;
    display: flex;
  }
  .table-wrap {
    background: #fff;
  }

  .product-img {
    width: 60px;
    max-height: 60px;
  }
}
</style>
